<template>
	<div>
		<!--顶部工具条-->
		<el-col :span="24">
			<el-form :inline="true">
				<el-form-item label="书籍名称:">
					<el-input clearable @clear='clearContent' v-model="filters.bookName" placeholder="请输入关键字"
						@input="(e) => (filters.bookName = utils.validForbid(e))">
					</el-input>
				</el-form-item>
				<el-form-item label="书籍分类:">
					<el-select v-model="filters.bizTypeId" clearable>
						<el-option v-for="item in bizTypeData" :key="item.key" :label="item.value" :value="item.key">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="审核状态:">
					<el-select v-model="filters.approval" clearable>
						<el-option v-for="item in approvalData" :key="item.value" :label="item.name"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
				</el-form-item>
			</el-form>
		</el-col>
		<!--列表-->
		<el-table :data="users" highlight-current-row @current-change="selectCurrentRow" v-loading="listLoading"
			@selection-change="selsChange" style="width: 100%" border>
			<!-- <el-table-column type="selection" width="50">
			</el-table-column> -->
			<!-- <el-table-column type="index" :index="indexMethod" label="编号" align="center" width="100"></el-table-column> -->
			<el-table-column prop="bizTypeName" label="书籍分类" align="center" show-overflow-tooltip
				width="150"></el-table-column>
			<el-table-column prop="bizTypeName" label="子分类" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<div>
						{{scope.row.dataTypeName}}{{scope.row.dataTargetName ? '|' : ''}}{{scope.row.dataTargetName}}
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="bookName" label="书籍名称" align="center">
			</el-table-column>
			<el-table-column prop="departmentName" label="解析状态" align="center" show-overflow-tooltip width="100">
				<template slot-scope="scope">
					<el-tag size="medium"
						:type="scope.row.analyseStatus == 1 ? 'warning' : scope.row.analyseStatus == 2 ? 'success' : 'danger'"
						disable-transitions>{{ scope.row.analyseStatusName }}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="roleNames" label="审核状态" align="center" width="100">
				<template slot-scope="scope">
					<el-tag size="medium"
						:type="scope.row.approval == 1 ? 'info' : scope.row.approval == 2 ? 'warning' : scope.row.approval == 3 ? 'success' : 'danger'"
						disable-transitions>{{ scope.row.approvalName }}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="createUserName" label="添加人" width="100" align="center">

			</el-table-column>
			<el-table-column prop="createTime" label="添加时间" width="200" align="center">

			</el-table-column>
			<el-table-column align="center" label="操作">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click.stop="checkBookContent(scope.row)">查看</el-button>
					<el-button v-if="scope.row.analyseStatus === 3" type="text" size="small" :loading="templateLoading" @click.stop="_reAnalyseBook(scope.row.bookId)">重新解析</el-button>
					<el-button v-if="(scope.row.approval === 1 || scope.row.approval === 4) && scope.row.analyseStatus == 2" type="text" size="small"
						@click.stop="handleCommit(scope.row)">提交审核</el-button>
					<!-- <el-button v-if="scope.row.bizTypeId === 2 && scope.row.approval === 3" type="text" size="small" @click.stop="openTableDialog(scope.row)">导入知识库</el-button> -->
				</template>
			</el-table-column>
		</el-table>
		<!--底部工具条-->
		<el-col :span="24" class="pageBar">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="pages.pageIndex" :page-sizes="pages.pageArr" :page-size="pages.pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="pages.dataCount">
			</el-pagination>
		</el-col>

		<!--新增-->
		<el-dialog title="新增" :visible.sync="addFormVisible" v-model="addFormVisible" :close-on-click-modal="false">
			<el-form v-if="addFormVisible" :model="addForm" label-width="130px" :rules="addFormRules" ref="addForm"  >
				<el-form-item label="书籍名称:" prop="bookName">
					<el-input v-model="addForm.bookName" auto-complete="off"></el-input>
				</el-form-item>
				<el-form-item label="书籍类型:">
					<el-select v-model="addForm.bookTypeId" clearable>
						<el-option v-for="item in bookTypeData" :key="item.key" :label="item.value" :value="item.key">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item  v-if="addForm.bookTypeId" label="上传书籍:">
					<el-upload class="upload-demo" drag :action="`${host}/book/uploadBookFile`"
						:headers="headers" :limit="1" :on-success="upLoadSuccess" :accept="addForm.bookTypeId === 3 ? '.xlsx' : '.docx'">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						<div class="el-upload__text">目前只支持只支持{{addForm.bookTypeId === 3 ? 'xlsx' : 'docx'}}文件，每个文件不超过100MB</div>
					</el-upload>
					<el-button type="primary" style="margin-top: 10px;" @click="_getBookTemplate" >{{addForm.bookTypeId == 1 ? '问答类模版' : addForm.bookTypeId == 2 ? '章节类模版' : '业务Excel模版'}}下载</el-button>
				</el-form-item>
				<el-form-item label="书籍分类:">
					<el-select v-model="addForm.bizTypeId" clearable @change="bizTypeChange">
						<el-option v-for="item in bizTypeData" :key="item.key" :label="item.value" :value="item.key">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="addForm.bizTypeId === 1" label="培训类型:">
					<el-radio-group v-model="addForm.dataTypeId" @change="addForm.dataTargetId = null">
						<el-radio v-for="item in dataTypeData" :key="item.key" :label="item.key">{{ item.value
							}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item v-if="addForm.bizTypeId === 2" label="操作员分类:">
					<el-select v-model="addForm.dataTargetId" clearable>
						<el-option v-for="item in knowledgeData" :key="item.targetId" :label="item.targetName"
							:value="item.targetId">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="addForm.bizTypeId === 1 && addForm.dataTypeId === 2" label="岗位分类:">
					<el-select v-model="addForm.dataTargetId" clearable>
						<el-option v-for="item in stationData" :key="item.targetId" :label="item.targetName"
							:value="item.targetId">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="addForm.bizTypeId === 1 && addForm.dataTypeId === 3" label="行业分类:">
					<el-select v-model="addForm.dataTargetId" clearable>
						<el-option v-for="item in tradeData" :key="item.targetId" :label="item.targetName"
							:value="item.targetId">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="知识分类:">
					<el-select v-model="addForm.contentTypeId" clearable>
						<el-option v-for="item in contentTypeData" :key="item.key" :label="item.value"
							:value="item.key">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="addFormVisible = false">取消</el-button>
				<el-button type="primary" @click.native="addSubmit" :loading="addLoading">提交</el-button>
			</div>
		</el-dialog>
		<!--编辑-->
		<el-dialog title="编辑" :visible.sync="editFormVisible" v-model="editFormVisible" :close-on-click-modal="false">
			<el-form :model="editForm" label-width="130px" :rules="editFormRules" ref="editForm">
				<el-form-item label="书籍昵称:" prop="bookName">
					<el-input v-model="editForm.bookName" auto-complete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="editFormVisible = false">取消</el-button>
				<el-button type="primary" @click.native="editSubmit" :loading="editLoading">保存</el-button>
			</div>
		</el-dialog>

		<!-- <el-dialog title="导入知识库" :visible.sync="dialogTableVisible" style="min-height: 900px;">
			<el-form>
				<el-form-item label="导入方式:" style="margin-bottom: 5 !important;">
					<el-radio-group v-model="knowledgeType">
						<el-radio :label="1">导入现有的知识库</el-radio>
						<el-radio :label="2">创建并导入知识库</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<el-form :inline="true" size="small">
				<el-form-item label="知识库名称:">
					<el-input v-model="knowledgeName" auto-complete="off" clearable
						@clear='clearKnowledgeName'></el-input>
				</el-form-item>
				<el-form-item v-if="knowledgeType === 1">
					<el-button type="primary" @click="_getKnowledgeDataPageList">查询</el-button>
				</el-form-item>
			</el-form>
			<el-table v-if="knowledgeType === 1" :data="dataKnowledge" cell-class-name="dialog-table-row"
				header-cell-class-name="dialog-table-header-row" border @selection-change="handleSelectionChange">
				<el-table-column :selectable="checkSelectable" type="selection" width="70"></el-table-column>
				<el-table-column prop="knowledgeName" label="知识库名称"></el-table-column>
				<el-table-column prop="bookCount" label="书籍数量" width="120"></el-table-column>
				<el-table-column prop="createUserName" label="添加人"></el-table-column>
				<el-table-column prop="createTime" label="添加时间"></el-table-column>
			</el-table>
			<div class="pageBar" v-if="knowledgeType === 1">
				<el-pagination @size-change="handleDialogTableSizeChange"
					@current-change="handleDialogTableCurrentChange" :current-page="dialogTabelPages.pageIndex"
					:page-sizes="dialogTabelPages.pageArr" :page-size="dialogTabelPages.pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="dialogTabelPages.dataCount">
				</el-pagination>
			</div>
			<div class="knowledge-footer">
				<el-button @click="dialogTableVisible = false">取消</el-button>
				<el-button type="primary" @click="handleKnowledgeConfirm">确认</el-button>
			</div>
		</el-dialog> -->
		<BookMenu v-if="showBookMenu" :visible.sync="showBookMenu" :bookId="itemValue.bookId"
			:approval="itemValue.approval" :title="itemValue.bookName" @confirm="handleConfirm" :refreshList="refreshList" :menuType="'book'" :contentTypeId="itemValue.contentTypeId">
		</BookMenu>
	</div>
</template>

<script>
import util from "../../../util/date";
import { validPhone } from "../../../util/validate";
import {
	getBookDataPageList,
	getByNamespace,
	getKnowledgeListByBizType,
	addBook,
	commitBookForAudit,
	editBook,
	deleteBook,
	getKnowledgeDataPageList,
	bookImportKnowledge,
	addKnowledge,
	getBookTemplate,
	reAnalyseBook
} from "../../api/api";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import BookMenu from "../../components/BookMenu";

export default {
	components: { Toolbar, BookMenu },
	data() {
		return {
			host: process.env.VUE_APP_BASE_URL,
			utils: '',
			users: [],
			roles: [],
			listLoading: false,
			currentRow: null,
			//列表选中列
			sels: [],
			//增删改查按钮
			buttonList: [],
			//顶部筛选条件
			filters: {
				bookName: "",
			},
			//关于分页的obj
			pages: {
				pageSize: 20, //默认每页条数
				pageArr: [10, 20, 30, 40], //默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			//新增界面是否显示
			addFormVisible: false,
			addLoading: false,
			addFormRules: {

			},
			//dialog数据
			addForm: {
				bookName: "",
				bizTypeId: "",
				contentTypeId: "",
				dataTypeId: "",
				bookTypeId: "",
				dataTargetId: "",
				obsPath: "",
				url: ""
			},
			//编辑界面是否显示
			editFormVisible: false,
			editLoading: false,
			editFormRules: {

			},
			//dialog数据
			editForm: {
				bookName: "",
				bookId: ""
			},
			approvalData: [
				{
					value: 1,
					name: '未提交'
				},
				{
					value: 2,
					name: '审核中'
				},
				{
					value: 3,
					name: '审核通过'
				},
				{
					value: 4,
					name: '审核拒绝'
				},
			],
			bizTypeData: [

			],
			contentTypeData: [

			],
			dataTypeData: [

			],
			bookTypeData: [

			],
			knowledgeData: [],
			stationData: [],
			tradeData: [],
			showBookMenu: false,
			itemValue: {},
			headers: {
				'Authorization': `Bearer ${window.localStorage.getItem("Token")}`,
			},
			dialogTableVisible: false,
			dialogTabelPages: {
				pageSize: 20, //默认每页条数
				pageArr: [10, 20, 30, 40], //默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			dataKnowledge: [],
			knowledgeType: 1,
			knowledgeName: '',
			knowledgeSelection: [],
			templateLoading: false
		};
	},
	methods: {
		// 编号
		indexMethod(index) {
			if (index + 1 < 10) {
				index = index + 1;
				index = '0' + index;
			}
			return `B000${index}`;
		},
		// 清空筛选框内容，重新获取数据
		clearContent() {
			this.getListData()
		},
		//当前行发生变化时
		selectCurrentRow(val) {
			this.currentRow = val;
		},
		//选项发生变化时
		selsChange: function (sels) {
			this.sels = sels;
		},
		//显示新增界面
		handleAdd() {
			this.addForm = {
				bookName: "",
				bizTypeId: "",
				contentTypeId: "",
				dataTypeId: "",
				bookTypeId: "",
				dataTargetId: "",
				obsPath: "",
				url: ""
			};
			this.addFormVisible = true;
		},
		//显示编辑界面
		handleEdit() {
			let row = this.currentRow;
			if (!row) {
				this.$message({
					message: "请选择要编辑的一行数据！",
					type: "error",
				});
				return;
			}
			this.editForm = {
				bookId: row.bookId,
				bookName: row.bookName,
			};
			this.editFormVisible = true;
		},
		checkBookContent: function (row) {
			this.itemValue = row
			this.showBookMenu = true

		},
		//新增
		addSubmit: function () {
			this.$refs.addForm.validate((valid) => {
				if (valid) {
					this.$confirm("确认提交吗？", "提示", {}).then(() => {
						this.addLoading = true;
						let params = Object.assign({}, this.addForm);
						this.addLoading = false;
						addBook(params).then((res) => {
							if (res.data.success) {
								this.addLoading = false;
								this.$message({
									message: '添加成功',
									type: "success",
								});
								this.addFormVisible = false;
								this.$refs["addForm"].resetFields();
								this.getListData();
							} else {
								this.addLoading = false;
								this.$message({
									message: res.data.message,
									type: "error",
								});
							}
						});
					});
				}
			});
		},
		//编辑
		editSubmit: function () {
			this.$refs.editForm.validate((valid) => {
				if (valid) {
					this.editLoading = true;
					let params = Object.assign({}, this.editForm);
					editBook(params).then((res) => {
						if (res.data.success) {
							this.editLoading = false;
							this.$message({
								message: '编辑成功',
								type: "success",
							});
							this.editFormVisible = false;
							this.getListData();
						} else {
							this.editLoading = false;
							this.$message({
								message: res.data.message,
								type: "error",
							});
						}
					});
				}
			});
		},
		//删除
		handleDel() {
			let row = this.currentRow;
			if (!row) {
				this.$message({
					message: "请选择要删除的一行数据！",
					type: "error",
				});
				return;
			}
			this.$confirm("确认删除该书籍吗?", "提示", {
				type: "warning",
			})
				.then(() => {
					this.listLoading = true;
					let params = { bookId: row.bookId };
					deleteBook(params).then((res) => {
						if (res.data.success) {
							this.listLoading = false;
							this.$message({
								message: res.data.message,
								type: "success",
							});
							this.userFormVisible = false;
							this.getListData();
						} else {
							this.listLoading = false;
							this.$message({
								message: res.data.message,
								type: "error",
							});
						}
					});
				})
				.catch(() => { });
		},
		// 查询
		getUsers() {
			this.pages.pageIndex = 1
			this.getListData()
		},
		refreshList(){
			this.getListData()
		},
		//获取书籍列表
		getListData() {
			this.listLoading = true;
			var params = {
				pageIndex: this.pages.pageIndex,
				pageSize: this.pages.pageSize,
				bookName: this.filters.bookName,
				approval: this.filters.approval,
				forAudit: false
			};
			if(this.filters.bizTypeId){
				params.bizTypeId = [this.filters.bizTypeId]
			}
			getBookDataPageList(params).then((res) => {
				this.users = res.data.response.data;
				this.listLoading = false;

				this.pages.dataCount = res.data.response.dataCount;
				this.pages.pageIndex = res.data.response.pageIndex;
				if (res.data.response.pageSize > 0) {
					this.pages.pageSize = res.data.response.pageSize;
				}
			});
		},
		_getKnowledgeDataPageList() {
			let params = {};
			params.bizTypeId = 2;
			params.pageIndex = this.dialogTabelPages.pageIndex;
			params.pageSize = this.dialogTabelPages.pageSize;
			params.knowledgeName = this.knowledgeName

			getKnowledgeDataPageList(params).then((res) => {
				this.dataKnowledge = res.data.response.data;
				this.dialogTabelPages.dataCount = res.data.response.dataCount;
			})
		},
		clearKnowledgeName() {
			this.knowledgeName = ''
			this._getKnowledgeDataPageList()
		},
		openTableDialog(val) {
			this._getKnowledgeDataPageList()
			this.dialogTableVisible = true
			this.itemValue = val;
			this.knowledgeType = 1
			this.knowledgeName = ''
		},
		callFunction(item) {
			this[item.func].apply(this, item);
		},
		// 分页
		handleCurrentChange(val) {
			this.pages.pageIndex = val;
			this.getListData();
		},
		handleSizeChange(val) {
			this.pages.pageSize = val;
			this.getListData();
		},
		handleDialogTableSizeChange(val) {
			this.dialogTabelPages.pageSize = val;
			// this.getListData();
			this._getKnowledgeDataPageList()
		},
		handleDialogTableCurrentChange(val) {
			this.dialogTabelPages.pageIndex = val;
			this._getKnowledgeDataPageList()

		},
		validForbid(value, number = 2550) {
			value = value
				.replace(
					/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
					""
				)
				.replace(/\s/g, "");
			if (value.length >= number) {
				this.$message({
					type: "warning",
					message: `输入内容不能超过${number}个字符`,
				});
			}
			return value;
		},
		handleConfirm() {

		},
		dataTypeChange(e) {
			this.addForm.dataTargetId = null
		},
		bizTypeChange(e) {
			this.addForm.dataTargetId = null

		},
		getbizTypeList() {
			getByNamespace({ namespace: 'BizService.OmsAicsService.BizType' }).then(res => {
				this.bizTypeData = res.data.response
			})
		},
		getBookTypeList() {
			getByNamespace({ namespace: 'BizService.OmsAicsService.BookType' }).then(res => {
				this.bookTypeData = res.data.response
			})
		},
		getDataTypeList() {
			getByNamespace({ namespace: 'BizService.OmsAicsService.DataType' }).then(res => {
				this.dataTypeData = res.data.response
			})
		},
		getContentTypeList() {
			getByNamespace({ namespace: 'BizService.OmsAicsService.ContentType' }).then(res => {
				this.contentTypeData = res.data.response
			})
		},
		getKnowledgeList(bizType, type) {
			getKnowledgeListByBizType({ bizType, type }).then(res => {
				if (bizType === 1 && type === 2) {
					this.tradeData = res.data.response
				}
				if (bizType === 1 && type === 1) {
					this.stationData = res.data.response
				}
				if (bizType === 2) {
					this.knowledgeData = res.data.response
				}
			})
		},
		handleCommit(row) {
			let params = {
				bookId: row.bookId
			};
			this.$confirm('确定提交审核？提交后审核人员将对您的书籍进行审核，审核期间将无法修改内容', row.bookName)
				.then(_ => {
					commitBookForAudit(params).then(res => {
						if (res.data.status == 200) {
							this.$message({
								message: '提交成功',
								type: "success",
							});
							this.getListData();
						}
					})
				})
				.catch(_ => { });
		},
		upLoadSuccess(res) {
			if (res.success) {
				this.addForm.obsPath = res.response.filePath
				this.addForm.url = res.response.fileUrl
			} else {
				this.$message({
					message: '上传失败, 请重试',
					type: "error",
				});
			}
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},

		checkSelectable(row) {
			return this.knowledgeType == 1
		},
		_bookImportKnowledge() {
			if (this.multipleSelection.length === 0) {
				this.$message({
					message: '请选择知识库名称',
					type: "error",
				});
				return
			}
			let arr = []
			this.multipleSelection.map((item) => {
				arr.push(item.knowledgeId)
			})
			let params = {
				bookId: this.itemValue.bookId,
				knowledgeIds: arr
			}
			bookImportKnowledge(params).then(res => {
				if (res.data.status == 200) {
					this.$message({
						message: '导入成功',
						type: "success",
					});
					this.dialogTableVisible = false
				} else {
					this.$message({
						message: '导入失败',
						type: "error",
					});
				}
			})
		},
		_addKnowledge() {
			if (this.knowledgeName === '') {
				this.$message({
					message: '请输入知识库名称',
					type: "error",
				});
				return
			}
			let params = {
				bookIds: [this.itemValue.bookId],
				knowledgeName: this.knowledgeName
			}
			addKnowledge(params).then(res => {
				if (res.data.status == 200) {
					this.$message({
						message: '创建成功',
						type: "success",
					});
					this.dialogTableVisible = false
				} else {
					this.$message({
						message: '创建失败',
						type: "error",
					});
				}
			})
		},
		handleKnowledgeConfirm() {
			if (this.knowledgeType == 1) {
				this._bookImportKnowledge()
			} else {
				this._addKnowledge()
			}
		},
		_getBookTemplate(){
			getBookTemplate({bookTypeId: this.addForm.bookTypeId}).then((res)=>{
				if(res.data.success){
					window.open(res.data.response.url)
				}
			})
		},
		_reAnalyseBook(bookId){
			this.templateLoading = true
			reAnalyseBook({bookId}).then((res)=>{
				this.templateLoading = false
				if(res.data.success){
					this.$message({
						message: '操作成功',
						type: "success",
					});
				}
			})
		}
	},
	mounted() {
		this.utils = util
		this.getListData();
		this.getbizTypeList()
		this.getDataTypeList()
		this.getBookTypeList()
		this.getContentTypeList()
		this.getKnowledgeList(1, 1)
		this.getKnowledgeList(1, 2)
		this.getKnowledgeList(2, 1)
		let routers = window.localStorage.router
			? JSON.parse(window.localStorage.router)
			: [];
		this.buttonList = getButtonList(this.$route.path, routers);
	},
};
</script>
<style scoped>
.el-upload-dragger .el-icon-upload {
	margin-top: 10px;
}



.rolesTop {
	width: 100%;
	display: flex;
}

.rolesInp {
	width: 300px;
	margin-right: 20px;
}

.pageBar {
	display: flex;
	justify-content: center;
	margin-top: 15px;
}

.knowledge-footer {
	margin-top: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

::v-deep .dialog-table-row {
	padding: 15px !important;
}

::v-deep .dialog-table-header-row {
	padding: 8px 15px !important;
}
</style>
